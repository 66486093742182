import React from "react"
import cx from "classnames"

import Layout from "../components/layout"
import { EURONAVIGATOR_TASKS_URL } from "../const"
import { IMG } from "../const"
import * as styles from "../assets/styles/euronavigator.module.scss"
import FeedbackSection from "../components/feedback-section"

const euronavigator = () => {
  return (
    <Layout pageTitle="Euronavigator">
      {() => {
        return (
          <>
            <div
              className={cx(styles.euronavigatorMain, "container-fluid")}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div className={cx(styles.euronavigatorBlock, "row")}>
                <div
                  className={cx(
                    styles.mainImageEuronavigator,
                    styles.obliqueBottom,
                    "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  )}
                >
                  <div className={styles.starImage}></div>
                  <div className={styles.topMenuBlank}></div>
                  <h1 className={styles.euronavigatorText}>Euronavigator</h1>
                  <div className={styles.underTitleTextEuronavigator}>
                    Euronavigator is a dashboard system that tracks the
                    implementation process of the EU‑Ukraine Association
                    Agreement
                  </div>
                  <a
                    href={EURONAVIGATOR_TASKS_URL}
                    className="goldButton__centered goldButton__w18 mb2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check out Euronavigator
                  </a>
                  <div className={styles.containerEuronavigator}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/003-MacBook-Space-Gray.png`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="2040"
                      height="1209"
                    />
                  </div>
                </div>
              </div>
              <div className={cx(styles.euronavigatorBlock, "row")}>
                <div
                  className={cx(
                    styles.executiveSummary,
                    "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  )}
                >
                  <div className={styles.containerEuronavigator}>
                    <div className="row">
                      <div
                        className={cx(
                          styles.leftSide,
                          "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                        )}
                      >
                        <div className={styles.obj1}>
                          <img
                            src={`${IMG.EURONAVIGATOR}/group-7-copy.svg`}
                            alt="Euronavigator"
                            loading="lazy"
                            width="47"
                            height="57"
                          />
                        </div>
                        <div className={styles.obj2}>
                          <img
                            src={`${IMG.EURONAVIGATOR}/rectangle-4-copy.svg`}
                            alt="Euronavigator"
                            loading="lazy"
                            width="51"
                            height="51"
                          />
                        </div>
                      </div>
                      <div
                        className={cx(
                          styles.rightSide,
                          "col-xs-12 col-sm-12 col-md-7 col-lg-7"
                        )}
                      >
                        <div className={styles.horLine}></div>
                        <h2>Executive Summary</h2>
                        <div className="row">
                          <div
                            className={cx(
                              styles.leftSideIn,
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3"
                            )}
                          >
                            <img
                              src={`${IMG.EURONAVIGATOR}/eu-ua.svg`}
                              alt="Euronavigator"
                              loading="lazy"
                              width="140"
                              height="139"
                            />
                          </div>
                          <div
                            className={cx(
                              styles.rightSideIn,
                              styles.text,
                              "col-xs-12 col-sm-12 col-md-9 col-lg-9"
                            )}
                          >
                            <p>
                              A few years ago,{" "}
                              <span className={styles.colorBlue}>Ukraine</span>{" "}
                              started the way of democratic transformation. At
                              this point, Ukraine is going through the process
                              of economic integration and political association
                              with the{" "}
                              <span className={styles.colorBlue}>EU</span>. The
                              Association Agreement requires the gradual
                              implementation many the EU Normative legal acts in
                              the Ukrainian legislation. Before the project
                              execution, Ukrainian Centre for European Policy
                              (UCEP) monitored the implementation process
                              manually with little clarity. LaSoft offered UCEP
                              a custom dashboard solution that automates data
                              collection and representation. Today our solution
                              save the time and efforts of UCEP employees and
                              provides 100% of visibility to anyone with
                              internet access.
                            </p>
                            <p>
                              We started working on the Euronavigator dashboard
                              system to track the implementation of legislative
                              acts for Ukraine`s realization of commitment
                              according to the Association Agreement.
                              Euronavigator aims to inform citizens about the
                              execution requirements and develop the ability to
                              monitor the progress towards realizing these
                              requirements.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={cx(
                  styles.aboutCompany,
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                )}
              >
                <div className={styles.cubeRight}></div>
                <div className={styles.containerEuronavigator}>
                  <div className="row">
                    <div
                      className={cx(
                        styles.leftSide,
                        "col-xs-12 col-sm-12 col-md-6 offset-md-1 col-lg-6 offset-lg-1"
                      )}
                    >
                      <div className={styles.verLine}></div>
                      <h2>
                        About the <br /> company/client
                      </h2>
                      <div className={styles.text}>
                        <p>
                          The{" "}
                          <span className={styles.colorBlue}>
                            Ukrainian Centre for European Policy
                          </span>{" "}
                          (UCEP) is an independent think-tank and advocacy group
                          founded in 2015.
                        </p>
                        <p>
                          The group’s mission is to promote reforms in Ukraine
                          to ensure democratic development, sustainable economic
                          growth and implementation of the EU-Ukraine
                          Association Agreement, with a particular focus on the
                          free trade area.
                        </p>
                        <div className={styles.logoUcep}>
                          <img
                            src={`${IMG.EURONAVIGATOR}/ucep-1-e-5-d-4099.svg`}
                            alt="Ukrainian Centre for European Policy"
                            loading="lazy"
                            width="292"
                            height="83"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={cx(
                        styles.rightSide,
                        "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                      )}
                    >
                      <div className={styles.obj3}>
                        <img
                          src={`${IMG.EURONAVIGATOR}/oval-4-copy-2.svg`}
                          alt="Euronavigator"
                          loading="lazy"
                          width="46"
                          height="47"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={cx(
                  styles.activities,
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                )}
              >
                <div className={styles.cubeLeft}></div>
                <div className={styles.containerEuronavigator}>
                  <div className="row">
                    <div
                      className={cx(
                        styles.leftSide,
                        "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                      )}
                    >
                      <div className={styles.obj2}>
                        <img
                          src={`${IMG.EURONAVIGATOR}/rectangle-4-copy.svg`}
                          alt="Euronavigator"
                          loading="lazy"
                          width="51"
                          height="51"
                        />
                      </div>
                    </div>
                    <div
                      className={cx(
                        styles.rightSide,
                        "col-xs-12 col-sm-12 col-md-7 col-lg-7"
                      )}
                    >
                      <div className="row">
                        <div
                          className={cx(
                            styles.leftSideIn,
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3"
                          )}
                        >
                          <div className={styles.horLine}></div>
                        </div>
                        <div
                          className={cx(
                            styles.rightSideIn,
                            styles.text,
                            "col-xs-12 col-sm-12 col-md-9 col-lg-9"
                          )}
                        >
                          <h2>Activities:</h2>
                          <ul>
                            <li>
                              Preparing and dissemination of expert-analytical
                              materials in order to promote reforms for
                              implementation of the EU-Ukraine Association
                              Agreement;
                            </li>
                            <li>
                              Promoting European values in Ukrainian society;
                            </li>
                            <li>
                              Informing a society about the opportunities and
                              advantages of a partnership with the European
                              Union;
                            </li>
                            <li>
                              Facilitation to increase economic, political and
                              trading partnership between Ukraine and the EU;
                            </li>
                            <li>
                              Informing the international community about
                              challenges and achievements in implementing
                              required reforms according to the Association
                              Agreement.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={cx(
                  styles.developmentTools,
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                )}
              >
                <div className={styles.circleRight}></div>
                <div className={styles.containerEuronavigator}>
                  <div className="row">
                    <div
                      className={cx(
                        styles.leftSide,
                        "col-xs-12 col-sm-12 col-md-7  col-lg-7"
                      )}
                    >
                      <div className={cx(styles.gridTools, "row")}>
                        <div className="col-xs-12 col-sm-6 col-lg-4 ">
                          <div
                            className={cx(
                              styles.itemTool,
                              styles.active,
                              styles.noTitle
                            )}
                          >
                            Development tools
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-4">
                          <div className={styles.itemTool}>
                            <div className={styles.itemToolTitle}>
                              Back-end:
                            </div>
                            <div className={styles.itemToolText}>
                              Ruby on Rails
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={cx(styles.gridTools, "row")}>
                        <div className="col-xs-12 col-sm-6 offset-lg-4 col-lg-4">
                          <div className={styles.itemTool}>
                            <div className={styles.itemToolTitle}>
                              Front-end:
                            </div>
                            <div className={styles.itemToolText}>
                              HTML <br /> CSS <br /> JavaScript
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-4">
                          <div className={styles.itemTool}>
                            <div className={styles.itemToolTitle}>Hosting:</div>
                            <div className={styles.itemToolText}>
                              DigitalOcean
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={cx(styles.gridTools, "row")}>
                        <div className="col-xs-12 col-sm-6 col-lg-4 ">
                          <div className={styles.itemTool}>
                            <div className={styles.itemToolTitle}>
                              Database:
                            </div>
                            <div className={styles.itemToolText}>
                              PostgreSQL
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={cx(
                        styles.rightSide,
                        "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                      )}
                    >
                      <div className={styles.obj1}>
                        <img
                          src={`${IMG.EURONAVIGATOR}/group-7-copy.svg`}
                          alt="Euronavigator"
                          loading="lazy"
                          width="47"
                          height="57"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={cx(
                  styles.theChallenge,
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                )}
              >
                <div className={styles.circleRight}></div>
                <div className={styles.lineRight}></div>
                <div className={styles.containerEuronavigator}>
                  <div className="row">
                    <div
                      className={cx(
                        styles.leftSide,
                        "col-xs-12 col-sm-12 col-md-3  col-lg-3"
                      )}
                    >
                      <div className={styles.obj1}>
                        <img
                          src={`${IMG.EURONAVIGATOR}/line.svg`}
                          alt="Euronavigator"
                          loading="lazy"
                          width="78"
                          height="79"
                        />
                      </div>
                      <div className={styles.obj3}>
                        <img
                          src={`${IMG.EURONAVIGATOR}/oval-4-copy-2.svg`}
                          alt="Euronavigator"
                          loading="lazy"
                          width="46"
                          height="47"
                        />
                      </div>
                    </div>
                    <div
                      className={cx(
                        styles.rightSide,
                        "col-xs-12 col-sm-12 col-md-9  col-lg-9"
                      )}
                    >
                      <div className={styles.horLineYellow}></div>
                      <h2>The Challenge</h2>
                      <div className={styles.textInfo}>
                        The primary customer’s need was to create a transparent
                        tool to monitor the implementation of the EU-Ukraine
                        Association Agreement. When we started working on the
                        project, the client couldn’t process the data easily.
                        Users had to write the required data in Excel tables to
                        create the Administrative Panel. A CSV file was
                        generated to be uploaded to the old version of the
                        Infographic.
                      </div>
                      <div className="row">
                        <div
                          className={cx(
                            styles.cloudBox,
                            "col-xs-12 col-sm-12 col-md-6  col-lg-6"
                          )}
                        />
                        <div
                          className={cx(
                            styles.text,
                            styles.textInfoCol,
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                          )}
                        >
                          <p>
                            Our highly-qualified team offered a unique solution
                            and the client received an easy-to-use interface
                            with the ability to search, filter and sort the
                            data. It was decided to create a bot service
                            (Scrapper) that helps to automate the processing of
                            the Eurolex website to gather keywords for the
                            future search of appropriate legal acts on the
                            website of The Verkhovna Rada of Ukraine (Supreme
                            Council).
                          </p>
                          <p>
                            Every day Scrapper checks the availability of new
                            euro integration legal acts on the Verkhovna Rada
                            web page. When new acts are detected Scrapper offers
                            to add them to the EU database for future
                            implementation tracking.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={cx(
                  styles.theSolution,
                  "col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1"
                )}
              >
                <div className={styles.lineLeft}></div>
                <div className={styles.containerEuronavigator}>
                  <div className={styles.horLine}></div>
                  <h2>The Solution </h2>
                  <div className={styles.text}>
                    The project is composed of{" "}
                    <span className={styles.colorBlue}>two</span> applications:{" "}
                    <br />
                    The Administrative Panel and Interactive infographics.
                  </div>
                  <div className={styles.separator} />
                  <div className="row">
                    <div
                      className={cx(
                        styles.leftSideIn,
                        "col-xs-12 col-sm-12 col-md-4 col-lg-4"
                      )}
                    >
                      <div className={styles.textBullet}>01</div>
                    </div>
                    <div
                      className={cx(
                        styles.rightSideIn,
                        styles.text,
                        "col-xs-12 col-sm-12 col-md-8 col-lg-8"
                      )}
                    >
                      <span className={styles.colorBlue}>
                        {" "}
                        The Administrative Panel
                      </span>{" "}
                      is intended to fill and edit data about European acts and
                      tasks accepted and accomplished by Ukraine to fulfill the
                      requirements of the EU-Ukraine Association Agreement. A
                      crucial function of the Administrative panel is processing
                      data that Scrapper gathers.
                    </div>
                  </div>
                  <div className={styles.separatorHalf}></div>
                  <div className="row">
                    <div
                      className={cx(
                        styles.leftSideIn,
                        "col-xs-12 col-sm-12 col-md-4 col-lg-4"
                      )}
                    >
                      <div className={styles.textBullet}>02</div>
                    </div>
                    <div
                      className={cx(
                        styles.rightSideIn,
                        styles.text,
                        "col-xs-12 col-sm-12 col-md-8 col-lg-8"
                      )}
                    >
                      An{" "}
                      <span className={styles.colorBlue}>
                        Interactive infographic
                      </span>{" "}
                      is an online tool representing the result of Ukraine’s
                      implementation of the Association Agreement. The data
                      updated in the Infographic is carried out after
                      legislation changes on both sides.
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.text}>
                    We started with{" "}
                    <span className={styles.colorBlue}>planning</span> and had
                    to spend some time in the client’s office to understand
                    better all tasks to be solved during the data processing.
                    The next step was the design development, which meant
                    several meetings with the client to find the most optimal
                    design solution for the Administrative Panel and Interactive
                    Infographic.
                  </div>
                </div>
              </div>
              <div
                className={cx(
                  styles.obliqueTop,
                  styles.obliqueBottom,
                  styles.screenShot,
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                )}
              >
                <div className={styles.herb}></div>
                <div className={styles.containerEuronavigator}>
                  <div className={styles.obj3}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/oval-4-copy-2.svg`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="46"
                      height="47"
                    />
                  </div>
                  <div className={cx(styles.img1, styles.shadow)}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/eunavigator-01hd.jpg`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="2680"
                      height="2190"
                    />
                  </div>
                  <div className={cx(styles.img2, styles.shadow)}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/eunavigator-04.jpg`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="2680"
                      height="1860"
                    />
                  </div>
                  <div className={cx(styles.img3, styles.shadow)}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/eunavigator-02.jpg`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="1340"
                      height="935"
                    />
                  </div>
                </div>
              </div>
              <div
                className={cx(
                  styles.developersInsight,
                  "col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1"
                )}
              >
                <div className={styles.cubeRight}></div>
                <div className={styles.cubeLeft}></div>
                <div className={styles.obj1}>
                  <img
                    src={`${IMG.EURONAVIGATOR}/group-7.svg`}
                    alt="Euronavigator"
                    loading="lazy"
                    width="47"
                    height="57"
                  />
                </div>
                <div className={styles.containerEuronavigator}>
                  <div className="row">
                    <div
                      className={cx(
                        styles.leftSideIn,
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3"
                      )}
                    >
                      <img
                        src={`${IMG.EURONAVIGATOR}/quote.svg`}
                        alt="Euronavigator quotes"
                        loading="lazy"
                        width="171"
                        height="163"
                      />
                    </div>
                    <div
                      className={cx(
                        styles.rightSideIn,
                        "col-xs-12 col-sm-12 col-md-9 col-lg-9"
                      )}
                    >
                      <div className="row">
                        <div
                          className={cx(
                            styles.autor,
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                          )}
                        >
                          <div className={styles.horLine}></div>
                          <h2>Developer’s insight</h2>
                        </div>
                        <div
                          className={cx(
                            styles.autor,
                            "col-xs-12 col-sm-5 col-md-5 col-lg-5"
                          )}
                        >
                          <strong>Taras Haiduchyk</strong>
                          <div>Back-end developer</div>
                          <img
                            src={`${IMG.EURONAVIGATOR}/au-dev.jpg`}
                            alt="Taras Haiduchyk"
                            loading="lazy"
                            width="208"
                            height="243"
                          />
                        </div>
                        <div
                          className={cx(
                            styles.autorInfo,
                            "col-xs-12 col-sm-7 col-md-7 col-lg-7"
                          )}
                        >
                          <p>
                            The Backend application consists of 3 parts: a bot
                            (a web crawler that allows automatical monitoring
                            the EU and Ukrainian online legislative base,
                            tracking and saving all changes), internal API (used
                            to fill and edit data in the manual mode) and public
                            API (provides users with real-time information about
                            the status of implementation of Ukraine–European
                            Union Association Agreement in infographic).
                          </p>
                          <p>
                            Working with a lot of data with many relations was
                            quite challenging. We faced performance issues,
                            which were resolved by database and caching query
                            optimizations. By using Elasticsearch, we have
                            provided our clients with an easy-searching
                            experience. Capybara library helped us to create the
                            bot which automated the process of data monitoring
                            and analysis.
                          </p>
                          <div className={styles.obj2}>
                            <img
                              src={`${IMG.EURONAVIGATOR}/rectangle-4-copy.svg`}
                              alt="Euronavigator"
                              loading="lazy"
                              width="51"
                              height="51"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={cx(
                        styles.leftSideIn,
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3"
                      )}
                    >
                      <img
                        src={`${IMG.EURONAVIGATOR}/quote.svg`}
                        alt="Euronavigator"
                        loading="lazy"
                        width="171"
                        height="163"
                      />
                    </div>
                    <div
                      className={cx(
                        styles.rightSideIn,
                        "col-xs-12 col-sm-12 col-md-9 col-lg-9"
                      )}
                    >
                      <div className="row">
                        <div
                          className={cx(
                            styles.autor,
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                          )}
                        >
                          <div className={styles.horLine}></div>
                          <h2>Designer’s insight</h2>
                        </div>
                        <div
                          className={cx(
                            styles.autor,
                            "col-xs-12 col-sm-5 col-md-5 col-lg-5"
                          )}
                        >
                          <strong>Maryana Shevchuk</strong>
                          <div>UI/UX designer</div>
                          <img
                            src={`${IMG.EURONAVIGATOR}/au-des.jpg`}
                            alt="Euronavigator Maryana Shevchuk"
                            loading="lazy"
                            width="208"
                            height="243"
                          />
                        </div>
                        <div
                          className={cx(
                            styles.autorInfo,
                            "col-xs-12 col-sm-7 col-md-7 col-lg-7"
                          )}
                        >
                          <p>
                            Regarding design, we faced the challenge of
                            visualizing very complex data in the simplest
                            possible way. Our target audience is very busy
                            people who are overloaded with information. So our
                            goal was to provide them with helpful information at
                            first glance. In our design process, we used Sketch
                            for visualization, Zeplin for collaboration with
                            developers and Marvel for prototyping.
                          </p>
                          <div className={styles.obj3}>
                            <img
                              src={`${IMG.EURONAVIGATOR}/line.svg`}
                              alt="Euronavigator"
                              loading="lazy"
                              width="78"
                              height="79"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.obj4}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/oval-4-copy-2.svg`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="46"
                      height="47"
                    />
                  </div>
                </div>
              </div>
              <div
                className={cx(
                  styles.obliqueTop,
                  styles.screenShotIpad,
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                )}
              >
                <div className={styles.containerEuronavigator}>
                  <div className={styles.obj2}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/rectangle-4-copy.svg`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="51"
                      height="51"
                    />
                  </div>
                  <div className={styles.obj4}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/group-7-copy.svg`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="47"
                      height="57"
                    />
                  </div>
                  <div className={styles.img1}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/i-pad-landscape.png`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="1408"
                      height="1067"
                    />
                  </div>
                  <div className={styles.feedback}>
                    <div className={styles.quoteImg}>
                      <img
                        src={`${IMG.EURONAVIGATOR}/fill-1.svg`}
                        alt="Euronavigator Client’s feedback"
                        loading="lazy"
                        width="142"
                        height="135"
                      />
                      <div className={styles.centhorLine}></div>
                    </div>
                    <h2>Client’s feedback</h2>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                        <div className="row">
                          <div
                            className={cx(
                              styles.autor,
                              "col-xs-12 col-sm-4 col-md-4 col-lg-4"
                            )}
                          >
                            <strong>Liubov Akulenko</strong>
                            <div>Executive director of UCEP</div>
                            <div>
                              <img
                                src={`${IMG.EURONAVIGATOR}/feed.png`}
                                alt="Euronavigator"
                                loading="lazy"
                                width="130"
                                height="150"
                              />
                            </div>
                          </div>
                          <div
                            className={cx(
                              styles.autorInfo,
                              "col-xs-12 col-sm-7 col-md-7 col-lg-7"
                            )}
                          >
                            <p>
                              NGO “Ukrainian Centre for European Policy” started
                              to cooperate with LaSoft almost a year and a half
                              ago and appreciates its team’s invaluable support
                              in developing of a back-end database and graphical
                              interface for an analytical monitoring product “AA
                              Navigator”. The creative technical and design
                              solutions proposed by LaSoft team were the core
                              inputs for re-launching and upgrading this
                              analytical tool that is widely used today from the
                              highest governmental level to ordinary citizens.
                              They are interested in Ukraine’s European
                              integration for comprehensive monitoring and
                              assessment of the progress in implementing the
                              Association Agreement between the EU and Ukraine.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={cx(
                  styles.results,
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                )}
              >
                <div className={styles.lineRight}></div>
                <div className={styles.circleRight}></div>
                <div className={styles.containerEuronavigator}>
                  <div className={styles.obj1}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/oval-4-copy-2.svg`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="46"
                      height="47"
                    />
                  </div>
                  <div className={styles.obj2}>
                    <img
                      src={`${IMG.EURONAVIGATOR}/line.svg`}
                      alt="Euronavigator"
                      loading="lazy"
                      width="78"
                      height="79"
                    />
                  </div>
                  <div className="row">
                    <div
                      className={cx(
                        styles.leftSide,
                        "col-xs-12 col-sm-12 offset-md-1 col-md-5 offset-lg-3 col-lg-3"
                      )}
                    >
                      <div className={styles.horLine}></div>
                      <h2>Results</h2>
                    </div>
                    <div
                      className={cx(
                        styles.rightSide,
                        styles.text,
                        "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                      )}
                    >
                      <p>
                        Our engineers and designers instantly drilled down to
                        the vision and idea of the project. As a result, the
                        client has received a
                        <span className={styles.colorBlue}>
                          {" "}
                          user-friendly interface{" "}
                        </span>
                        with comprehensive abilities for
                        <span className={styles.colorBlue}>
                          {" "}
                          data processing
                        </span>
                        .
                      </p>
                      <p>
                        Our <span className={styles.colorBlue}> solution </span>{" "}
                        gives impressively long-term customer advantages because
                        the Association Agreement will be implemented from 2014
                        to 2025. Our solutions will be working out the process
                        of implementing the Association Agreement for 8 years.
                      </p>
                      <p>
                        The ability to automate data processing and synchronize
                        both application databases helped our client to save
                        time and money as they are a nonprofit organization.
                      </p>
                      <a
                        href={EURONAVIGATOR_TASKS_URL}
                        className="goldButton__centered goldButton__w18 mb2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Check out Euronavigator
                      </a>
                    </div>
                  </div>
                </div>
                <div className={styles.bottomImg}>
                  <img
                    src={`${IMG.EURONAVIGATOR}/euronav2.jpg`}
                    alt="Euronavigator"
                    loading="lazy"
                    width="1900"
                    height="1267"
                  />
                </div>
              </div>
            </div>
            <FeedbackSection number={0} />
          </>
        )
      }}
    </Layout>
  )
}

export default euronavigator
